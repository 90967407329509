<template>
  <div class="fixed-fullscreen top-0 left-0 w-full h-full z-50">
    <transition name="fade">
      <div v-if="isOpen">
        <div
          class="absolute top-0 left bg-black opacity-80 w-full h-full"
          @click="close"
        ></div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showCloseButton"
        class="fixed top-4 right-4 sidebar-close z-50"
        @click="close"
      >
        <IconsCloseIcon />
      </div>
    </transition>
    <transition name="slide">
      <div
        v-if="isOpen"
        class="absolute top-0 right-0 h-full sidebar-inner bg-white overflow-y-auto js-freeze-body"
      >
        <div class="">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(["close"]);
const isOpen = ref(false);
const showCloseButton = ref(false);

const modalId = `sidebar-${Math.random().toString(36).substring(2, 9)}`;
const modalStore = useModalStore();

onMounted(() => {
  modalStore.registerModal(modalId);
  isOpen.value = true;
  setTimeout(() => {
    showCloseButton.value = true;
  }, 300);
});

useEventListener("keydown", (event) => {
  if (event.key === "Escape" && isOpen.value) {
    if (modalStore.isTopModal(modalId)) {
      modalStore.unregisterModal(modalId);
      close();
    }
  }
});

function close() {
  emits("close");
}
</script>
